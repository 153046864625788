<template>
	<div>
		<el-dialog title="用户协议" :visible.sync="showAgreement" width="50%" style="z-index: 99999;">
			<div v-html="agreeMent" class="ql-editor" style="max-height: 400px;"></div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="closeDialog">关闭</el-button>
				<el-button v-if="typeVal=='1'" type="primary" @click="agreeMentFun" :disabled="count>0">已阅读完毕并同意  <span v-if="count">{{ '('+count+')' }}</span></el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import "quill/dist/quill.core.css";
	import "quill/dist/quill.snow.css";
	import "quill/dist/quill.bubble.css";
	import {
		joinSystem
	} from "@/api/user";
	import {
		getContentBykey
	} from "@/api/api";
	import {
		getToken,
		setToken,
		removeToken,
		getKey,
		setKey
	} from '@/utils/auth'
	export default {
		props:{
			//类型 1 - 登录, 2-个人中心
			typeVal:{
				type:String,
				default:'1'
			},
			//手机号
			phone:{
				type:Number | String,
				default:null
			}
		},
		data(){
			return{
				showAgreement: false, //用户协议弹框
				agreeMent: null, //用户协议内容
				count:0,
				timer:null
			}
		},
		destroyed() {
		    // 组件销毁时清除计时器
		    clearInterval(this.timer);
		},
		methods: {
			//关闭
			closeDialog(){
				if(this.typeVal=='1'){
					this.$store.commit('SET_TOKEN', '');
					this.$store.commit('SET_USERID', '');
					this.$store.commit('SET_USERINFO', '');
					localStorage.setItem('userInfo','')
					localStorage.setItem('userId','')
					setKey('');
					setToken('');
				}
				this.showAgreement = false;
				this.agreeMent = '';
			},
			getData() {
				getContentBykey('user_agreement').then((res)=>{
					this.agreeMent = res.data.content;
					this.showAgreement = true;
					if(this.typeVal == '1'){
						this.startCountdown()
					}
					
				})
			},
			// 开始倒计时
			startCountdown() {
			 this.count = 30;
			  this.timer = setInterval(() => {
				if (this.count > 0) {
				  this.count--;
				} else {
				  this.stopCountdown();
				}
			  }, 1000);
			},
			// 停止倒计时
			stopCountdown() {
			  clearInterval(this.timer);
			  this.timer = null;
			  this.count = 0; // 重置倒计时秒数
			},
			/* 同意条款 */
			agreeMentFun() {
				joinSystem({username:this.phone}).then((res) => {
					this.$emit('agreeSuccess')
				})
			},
		}
	}
</script>

<style>
</style>