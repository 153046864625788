<template>
	<div class="navbar">
		<div class="navbar-container">
			<div class="home-container">
				<router-link to="/home">
					<div class="home-wrapper">
						<img src="../../assets/home.png" class="home-icon">
					</div>
				</router-link>
			</div>
			<div class="right-menu">
				<div v-if="msgNum>0"
					style="font-size: 13px; background-color: #ff0000;position: relative;right: -45px;top:-20px; color: #fff;width: 20px;height: 20px;display: flex;align-items: center;justify-content: center; border-radius: 50%;">
					{{msgNum}}
				</div>
				<img src="@/assets/msg.png" class="user-avatar" @click="onShowMessage">
				<img :src="userInfo.avatar?userInfo.avatar:require('@/assets/user.png')" class="user-avatar2" @click="onShowDialog">
			</div>
		</div>
		<div class="user-container">
			<div class="username">欢迎您，{{userInfo.nickName}}</div>
			<div class="time-container">
				<span class="time-title">{{deteInfo.time}}</span>
				<span class="data-title">{{deteInfo.day}} {{deteInfo.week}}</span>
			</div>
		</div>
		<div class="message-dialog" :style="messageDialog?{display: 'flex'}:{display: 'none'}" @click="onCloseDialog">
			<div class="message-container">

				<div class="message-title flex-space-between px-30 boxSizing">
					<span>消息
						<span style="font-size: 13px;cursor: pointer;color: #fff;"
							@click="allReadNotice()">全部标记已读</span>
					</span>

					<i class="el-icon-close fontWeight font34 pointer" @click="messageDialog=false"></i>
				</div>
				<el-scrollbar class="item-scrollbar" wrap-class="default-scrollbar__wrap">
					<div class="item-view" v-for="(item,index) in messageList" :key="index"
						@click.stop="onMsgData(item)">
						<div class="item-icon-view">
							<el-image class="msg-icon" :src="require('@/icons/svg/msg.svg')"></el-image>
						</div>
						<div class="item-data">
							<div class="item-msg-title">{{item.title}}</div>
							<div class="item-msg-text mt-15">{{item.message}}</div>
							<div class="del-title mt-15">{{item.createTime}}</div>
						</div>
						<div class="del-view">
							<el-image class="del-icon" v-if="item.status=='1'" @click="delNotice(item)"
								:src="require('@/icons/svg/del.svg')"></el-image>
							<span v-else>未读</span>
						</div>
					</div>
				</el-scrollbar>
			</div>
			<!-- 		<div class="message-container" v-else>
				<div class="message-title">
					<el-image class="close-icon" :src="require('@/icons/svg/close.svg')" @click.stop="onMsgClose()">
					</el-image>
					<span>系统通知</span>
				</div>
				<el-scrollbar class="item-scrollbar" wrap-class="default-scrollbar__wrap">
					<div class="message-content">
					</div>
				</el-scrollbar>
			</div> -->
		</div>
		<div class="dialog-container " @click.prevent="onCloseDialog(false)" v-if="userDialog">
			<div class="dialog-view position-relative" @click.stop="((e)=>{return})">
				<div class="dialog-item" style="margin-top: 45px;">
					<!-- <div class="dialog-item-icon" v-if="userInfo.avatar">
						<el-image fit="cover" class="item-icon" :src="userInfo.avatar"
							:preview-src-list="[userInfo.avatar]" style="border-radius: 16px"></el-image>
					</div>
					<div class="dialog-item-icon" v-else style="background-color: #3760a7;border-radius: 50%;">
						<el-image :preview-src-list="[require('@/icons/svg/icon.svg')]" class="item-icon item-icon-head"
							:src="require('@/icons/svg/icon.svg')"></el-image>
					</div>
					<div class="item-title-view">
						<div class="item-title">{{userInfo.nickName}}</div>
					</div>
					<div class="item-text" v-if="userInfo.endTime">会员剩余：{{userInfo.endTime}}</div> -->
					<div style="display: flex;">
						<el-image fit="cover" class="item-icon" :src="userInfo.avatar"
							:preview-src-list="[userInfo.avatar]"
							style="border-radius: 10px;width:70px;height:70px"></el-image>
						<div class="item-title" style="margin-left: 20px;line-height: 35px;width: 320px;">
							<div>
								{{userInfo.nickName}}
							</div>
							<div style="display:flex;justify-content: space-between;">
								<div style="font-weight: 100;font-size:16px;">
									会员剩余：<span style="color:#169bd5;font-weight: 600;">{{userInfo.endTime}}</span>
								</div>
								<span style="margin-left: 30px;font-size: 16px;color:#169bd5;cursor: pointer;"
									@click.stop="onNenewal">会员续费</span>
							</div>
						</div>
					</div>
				</div>
				<div class="dialog-item">
					<div style="display: flex;">
						<el-image fit="cover" class="item-icon" :src="userInfo.lawyerAvatar"
							:preview-src-list="[userInfo.lawyerAvatar]"
							style="border-radius: 10px;width:70px;height:70px"></el-image>
						<div class="item-title" style="margin-left: 20px;line-height: 35px;width: 320px;">
							<div>
								首席服务律师
							</div>
							<div style="display:flex;justify-content: space-between;">
								<div style="font-weight: 100;font-size:16px;">
									{{userInfo.lawyerName}}
								</div>
								<span style="margin-left: 30px;font-size: 16px;color:#169bd5;cursor: pointer;"
									@click="$router.push('/message?lawyerName='+userInfo.lawyerName+'&lawyerId='+userInfo.lawyerId);userDialog=false;">去留言</span>
							</div>
						</div>
					</div>
					<!-- <div class="dialog-item-icon" v-if="userInfo.lawyerAvatar">
						<el-image fit="cover" class="item-icon" :src="userInfo.lawyerAvatar"
							:preview-src-list="[userInfo.lawyerAvatar]"></el-image>
					</div>
					<div v-else class="dialog-item-icon" style="background-color: #3760a7;border-radius: 50%;">
						<el-image class="item-icon item-icon-head" :src="require('@/icons/svg/icon.svg')"></el-image>
					</div>
					<div class=" flex-space-between flex1">
						<div class="flex1 ml-30">
							<div class="item-title">首席服务律师</div>
							<div class="item-text2">{{userInfo.lawyerName}}</div>
						</div>
						<div class="mr-30">
							<el-button type="info" icon="el-icon-message" round
								@click="$router.push('/message?lawyerName='+userInfo.lawyerName+'&lawyerId='+userInfo.lawyerId);userDialog=false;">去留言</el-button>
						</div>
					</div> -->
				</div>
				<div class="dialog-line"></div>
				<!-- <div class="dialog-item" style="padding: 10px 0px;" @click.stop="onNenewal">
					<el-image class="item-icon" :src="require('@/icons/svg/vip.svg')"></el-image>
					<div class="item-title-view">
						<div class="item-text2 pointer">会员续费</div>
					</div>
				</div> -->

				<div style="width: 100%; margin-left: 20px;margin-top: 40px; " @click.stop="isSetting=!isSetting">
					<div style="display: flex;align-items: center;">
						<el-image class="item-icon2" :src="require('@/assets/settle.png')"
							style="width: 30px;height: 30px;"></el-image>
						<div class="item-text2 pointer" id="set" style="margin-left: 20px;">设置</div>
					</div>
					<div v-show="isSetting" class="set_box w-100  boxSizing" style="padding-left: 80px;">
						<div class="py-10  boxSizing fontColor-999 pointer borderBottmon"
							@click.stop="showSetPass=true;onCloseDialog(false)">修改密码</div>
						<div class="py-10 boxSizing fontColor-999 pointer" @click="open=true;userDialog=false;">
							修改头像
						</div>
					</div>
				</div>
				<div style="width: 100%;height:80px;display: flex;align-items: center;margin-left: 20px; margin-top: 10px;"
					@click="feedback()">
					<el-image class="item-icon2" :src="require('@/assets/feedback.png')"
						style="width: 30px;height: 30px;"></el-image>
					<div class="item-text2 pointer" id="set" style="margin-left: 20px;">意见反馈</div>
				</div>
				<div style="width: 100%;height:80px;display: flex;align-items: center;margin-left: 20px; "
					@click.stop="showMessage=true;userDialog=false;">
					<el-image class="item-icon2" :src="require('@/assets/message.png')"
						style="width: 30px;height: 30px;"></el-image>
					<div class="item-text2 pointer" id="set" style="margin-left: 20px;">我的留言</div>
				</div>
				<!-- <div class="flex-column pl-20 py-30 boxSizing w-100" @click.stop="isSetting=!isSetting">
					<div class="flex-box w-100">
						<el-image class="item-icon2" :src="require('@/icons/svg/set.svg')"></el-image>
						<div class="item-title-view">
							<div class="item-text2 pointer" id="set">设置</div>
						</div>
					</div>

				</div> -->
				<!-- <el-collapse v-model="activeNames" @change="handleChange">
				  <el-collapse-item title="效率 Efficiency" name="3">
				    <div>简化流程：设计简洁直观的操作流程；</div>
				    <div>清晰明确：语言表达清晰且表意明确，让用户快速理解进而作出决策；</div>
				    <div>帮助用户识别：界面简单直白，让用户快速识别而非回忆，减少用户记忆负担。</div>
				  </el-collapse-item>
				</el-collapse> -->
				<!-- <div class="dialog-item" style="padding: 10px 0px;" @click="feedback()">
					<el-image class="item-icon3" :src="require('@/assets/feedback.png')"></el-image>
					<div class="item-title-view">
						<div class="item-text2 pointer">意见反馈</div>
					</div>
				</div>
				<div class="dialog-item" style="padding: 10px 0px;" @click.stop="showMessage=true;userDialog=false;">
					<i class="el-icon-s-comment ml-10 font28"></i>
					<div class="item-title-view">
						<div class="item-text2 pointer">我的留言</div>
					</div>
				</div> -->
				<div class="dialog-line" style="margin-top: 20px;"></div>
				<div class="dialog-item" style="padding: 10px 0px;" @click="logout()">
					<el-image class="item-icon3" :src="require('@/icons/svg/exit.svg')"></el-image>
					<div class="item-title-view">
						<div class="item-text2 pointer">退出登录</div>
					</div>
				</div>
				<div class=" w-100 flex-space-between px-20 boxSizing"
				 style="position: absolute;left:0;bottom: 20px;">
					
					<span class="pointer fontWeight font18" style="color:#169bd5;" @click="showAgreen">用户协议</span>
					<i class="el-icon-error pointer font30 fontColor-333" @click="onCloseDialog"></i>
				</div>
			</div>
			
		</div>

		<el-dialog :title="title" :visible.sync="open" width="800px" append-to-body @opened="modalOpened"
			@close="closeDialog">
			<el-row>
				<el-col :xs="24" :md="12" :style="{height: '350px'}">
					<vue-cropper ref="cropper" :img="options.img" :info="true" :autoCrop="options.autoCrop"
						:autoCropWidth="options.autoCropWidth" :autoCropHeight="options.autoCropHeight"
						:fixedBox="options.fixedBox" :outputType="options.outputType" @realTime="realTime"
						v-if="visible" />
				</el-col>
				<el-col :xs="24" :md="12" :style="{height: '350px'}">
					<div class="avatar-upload-preview">
						<img :src="previews.url" :style="previews.img" />
					</div>
				</el-col>
			</el-row>
			<br />
			<el-row>
				<el-col :lg="2" :sm="3" :xs="3">
					<el-upload action="#" :http-request="requestUpload" :show-file-list="false"
						:before-upload="beforeUpload">
						<el-button size="small">
							选择
							<i class="el-icon-upload el-icon--right"></i>
						</el-button>
					</el-upload>
				</el-col>
				<el-col :lg="{span: 1, offset: 2}" :sm="2" :xs="2">
					<el-button icon="el-icon-plus" size="small" @click="changeScale(1)"></el-button>
				</el-col>
				<el-col :lg="{span: 1, offset: 1}" :sm="2" :xs="2">
					<el-button icon="el-icon-minus" size="small" @click="changeScale(-1)"></el-button>
				</el-col>
				<el-col :lg="{span: 1, offset: 1}" :sm="2" :xs="2">
					<el-button icon="el-icon-refresh-left" size="small" @click="rotateLeft()"></el-button>
				</el-col>
				<el-col :lg="{span: 1, offset: 1}" :sm="2" :xs="2">
					<el-button icon="el-icon-refresh-right" size="small" @click="rotateRight()"></el-button>
				</el-col>
				<el-col :lg="{span: 2, offset: 6}" :sm="2" :xs="2">
					<el-button type="primary" size="small" @click="uploadImg()">提 交</el-button>
				</el-col>
			</el-row>
		</el-dialog>

		<el-dialog title="修改密码" :close-on-click-modal="false" :visible.sync="showSetPass" width="30%">
			<div>
				<el-form :model="passForm" :rules="rules" ref="passForm" label-width="100px" class="demo-ruleForm">
					<el-form-item label="旧密码" prop="oldPassword">
						<el-input v-model="passForm.oldPassword" type="password"></el-input>
					</el-form-item>
					<el-form-item label="新密码" prop="newPassword">
						<el-input v-model="passForm.newPassword" type="password"></el-input>
					</el-form-item>
					<el-form-item label="确认密码" prop="confirmPassword">
						<el-input v-model="passForm.confirmPassword" type="password"
							@change="equalToPassword"></el-input>
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="showSetPass = false">取 消</el-button>
				<el-button type="primary" @click="setPassSubmit">确 定</el-button>
			</span>
		</el-dialog>



		<el-dialog title="我的留言" :visible.sync="showMessage" width="75%" @open="getMessage(1)">
			<div class="w-100">
				<el-table :data="leaveMessage" style="width: 100%" height="450"
					:header-cell-style="{'text-align':'center','background':'#f2f2f2','border':'none','font-size':'18px'}"
					:cell-style="{'text-align':'center','font-size':'17px'}"  @selection-change="handleSelectionChange">
					<el-table-column
					      type="selection"
					      width="55">
					</el-table-column>
					<el-table-column fixed prop="userName" label="姓名" width="220">
					</el-table-column>
					<el-table-column prop="question" label="留言内容" width="">
					</el-table-column>
					<el-table-column prop="createTime" label="提交时间" width="">
					</el-table-column>
					<el-table-column prop="reply" label="回复内容" width="">
					</el-table-column>
					<el-table-column prop="replyTime" label="回复时间" width="">
					</el-table-column>
					<el-table-column prop="status" label="状态" width="">
						<template slot-scope="scope">
							<!-- 0提交、1已回复、2已完成 -->
							<span class="pointer ml-20"
								:style="{'color':scope.row.status=='2'?'#00aa7f':''}">{{scope.row.status=='0'?'已提交':scope.row.status=='1'?'已回复':'已完成'}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="zip" label="操作" width="180">
						<template slot-scope="scope">
							<span class="fontColor-index pointer ml-20" v-if="scope.row.status == '1'"
								@click="readed(scope.row)">设置已读</span>
								
								<span class="fontColor-red pointer ml-20" v-if="scope.row.status == '2'"
									@click="delMessage(scope.row.id)">删除</span>
						</template>
					</el-table-column>
				</el-table>

				<div class="flex-center mt-20">
					<pagination :current="messageParams.pageNum" :total="messageTotal" @change="getMessage">
					</pagination>
				</div>
			</div>
		</el-dialog>
		<userAgreement ref="userAgreement" :phone="userInfo.phone" typeVal="2"></userAgreement>
		<!-- 消息；铃声 -->
		<audio style="display:none;" src="@/assets/mp3/duang.mp3"  ref="audioPlayer"></audio>
	</div>
</template>

<script>
	import userAgreement from "@/components/userAgreement/index.vue"
	import {
		removeToken
	} from '@/utils/auth'
	import {
		getCurrentUser,
		getMemberPrice,
		uploadAvatar,
		leaveMessageList,
		leaveMessageEdit,
		leaveMessageDel
	} from "@/api/user"
	import {
		noticeList,
		updateUserPwd,
		readAllNotice,
		readNotice,
		removeNotice
	} from "@/api/index"
	import {
		VueCropper
	} from "vue-cropper";
	import {
		mapGetters
	} from "vuex";
	import {
		debounce
	} from '@/utils'
	export default {
		components: {
			VueCropper,
			userAgreement
		},
		computed: mapGetters(['uid','refashData']),
		computed: {
			// ...mapGetters([
			// 	'avatar'
			// ])
		},

		data() {
			return {
				// 是否显示cropper
				visible: false,
				// 弹出层标题
				title: "修改头像",
				previews: {},
				resizeHandler: null,
				// 是否显示弹出层
				open: false,
				options: {
					img: require('@/icons/svg/icon.svg'), // 裁剪图片的地址
					autoCrop: true, // 是否默认生成截图框
					autoCropWidth: 200, // 默认生成截图框宽度
					autoCropHeight: 200, // 默认生成截图框高度
					fixedBox: true, // 固定截图框大小 不允许改变
					outputType: "png" // 默认生成截图为PNG格式
				},
				messageDialog: false,
				isMessage: true,
				userDialog: false,
				thisTime: '',
				thisData: '',
				memberDay: '', //会员剩余天数
				avatar2: '', //头像
				messageList: [],
				userInfo: {}, //用户信息
				deteInfo: {
					time: "",
					day: "",
					week: "",
				},
				time: null, //定时器
				isSetting: false, //是否显示设置
				showSetPass: false, //显示修改密码弹框
				passForm: {
					oldPassword: "",
					newPassword: "",
					confirmPassword: ""
				},
				rules: {
					oldPassword: [{
						required: true,
						message: '旧密码不能为空',
						trigger: 'blur'
					}],
					newPassword: [{
							required: true,
							message: '新密码不能为空',
							trigger: 'blur'
						},
						{
							min: 6,
							max: 20,
							message: '长度在 6 到 20 个字符',
							trigger: 'blur'
						}
					],
					confirmPassword: [{
						required: true,
						message: '确认密码不能为空',
						trigger: 'blur'
					}],
				},
				replyShow: false,
				replyInfo: {},
				replyData: {}, //回复参数
				messageTotal: 0,
				leaveMessage: [], //留言列表
				showMessage: false, //留言显隐
				messageParams: { //参数
					type: 0,
					pageSize: 10,
					pageNum: 1
				},
				msgNum: 0, //消息数量
				activeName: '',
				showAudio:false,//是否显示播放
			}
		},
		created() {},
		beforeDestroy() {
			this.time = null;
			clearInterval(this.time);
		},
		mounted() {
			console.log("--------",this.$store.state.dataInfo)
			this.getUserInfo();
			this.time = setInterval(() => {
				this.getTime()
			}, 1000);
			this.getNoticeList(1,true);


		},
		watch: {
			"$socketPublic.state.msg": {
				immediate: true,
				deep:true,
				handler(newVal, oldVal) {
					console.log("newValnewValnewValnewVal", newVal);
					if(newVal && newVal.data){
						this.getNoticeList(1,false);
						// if(JSON.parse(newVal.data).type == 'message'){
							this.playMessageAudio();
						// }
					}
				},
			},
			"$store.state.user.refashData": {
				deep:true,
				handler(newVal, oldVal) {
					if(newVal){
						this.getUserInfo();
						this.$store.commit('user/SET_REFASHDATA','')
					}
				},
			}
		},

		methods: {
			//删除留言单个
			delMessage(id){
				this.$confirm('确认删除?', '提示', {
				  confirmButtonText: '确定',
				  cancelButtonText: '取消',
				  type: 'warning'
				}).then(() => {
					leaveMessageDel({id:[id]}).then(res=>{
						this.$message({
							type: 'success', 
							message: '删除成功!'
						});
						this.getMessage()
					})
				}).catch(() => {
				});
			},
			//删除多选
			handleSelectionChange(val){
				
			},
			//显示用户协议
			showAgreen(){
				this.onShowDialog()
				this.$refs.userAgreement.getData();
			},
			/* 播放消息提示 */
			playMessageAudio() {
				this.showAudio = true;
				setTimeout(()=> {
					const audioPlayer = this.$refs.audioPlayer;
					audioPlayer.load(); // 重新加载音频
					audioPlayer.currentTime = 0; // 重置播放进度为0
					if (audioPlayer) {
						audioPlayer.play().catch((error) => {
							audioPlayer.load(); // 重新加载音频
							audioPlayer.currentTime = 0; // 重置播放进度为0
							this.showAudio = false;
							console.error('自动播放失败:', error);
						});
					}
				}, 500);
			},
			delNotice(item) {
				removeNotice([item.id]).then(res => {
					this.$message.success("删除成功");
					this.getNoticeList(1,false);
				})
			},
			handleClick() { //消息切换

			},
			allReadNotice() { //标记全部为已读
				readAllNotice().then(res => {
					this.getNoticeList(1,false);
				})
			},
			/* 已读信息信息 */
			readed(item) {
				if (item.status == '0') {
					this.$message.error("请等待律师回复");
					return
				}
				if (item.status == '2') {
					this.$message.error("已完成状态不可操作");
					return
				}
				leaveMessageEdit({
					lawyerRead: item.status,
					userRead: 1,
					status: 2,
					id: item.id
				}).then(() => {
					this.$message.success("已读");
					this.getMessage(1)
				})
			},
			/* 获取用户留言列表 */
			getMessage(e) {
				this.leaveMessage = []
				if (e) {
					this.messageParams.pageNum = e;
				}
				leaveMessageList(this.messageParams).then((res) => {
					this.leaveMessage = res.rows;
					this.messageTotal = res.total;

				})
			},
			/* 修改密码 */
			setPassSubmit() {
				this.$refs['passForm'].validate((valid) => {
					if (valid) {
						updateUserPwd(this.passForm.oldPassword, this.passForm.newPassword).then(response => {
							this.$message.success("修改成功");
							this.showSetPass = false;
							for (let key in this.passForm) {
								this.passForm[key] = ""
							}
							this.logout();
						});
					}
				})
			},
			/* 验证确认密码 */
			equalToPassword(e) {
				console.log("equalToPassword", e)
				if (this.passForm.newPassword !== this.passForm.confirmPassword) {
					this.$message.error('两次输入的密码不一致');
					this.passForm.confirmPassword = ""
				}
			},
			/* 获取时间 */
			getTime() {
				this.deteInfo.time = this.$funs.getNowTime('hh-mm');
				this.deteInfo.day = this.$funs.getNowTime('mm-dd');
				this.deteInfo.week = this.$funs.getNowTime('week');
			},
			/* 获取通知消息 */
			getNoticeList(e,type) {
				noticeList({
					noticeType: 1,
					pageSize: 10,
					pageNum: e ? e : 1
				}).then((res) => {
					this.messageList = res.rows;
					this.msgNum = res.msg ? parseInt(res.msg) : 0;
					if(this.messageList.length>0&&type){
						for (var i = 0; i < this.messageList.length; i++) {
							let item = this.messageList[i];
							if(item.status!=1){
								this.playMessageAudio();
								break
							}
						}
					}
				})
			},
			/* 获取用户信息 */
			getUserInfo() {
				getCurrentUser().then((res) => {
					// this.userName = res.data.nickName;
					// this.avatar2 = res.data.avatar;
					// this.memberDay = res.data.endTime;
					this.userInfo = res.data;
					this.messageParams.lawyerId = this.userInfo.lawyerId;
					this.getMessage();
				});
			},
			feedback() {
				this.onCloseDialog()
				this.$router.push('/feedback')
			},
			logout() {
				this.$socketPublic.dispatch('closeWebSocket');
				this.onCloseDialog()
				removeToken();
				this.$router.push(`/login?redirect=${this.$route.fullPath}`)
			},
			onShowMessage() {
				this.messageDialog = true;
			},
			onMsgData(item) {
				item.status = 1;
				readNotice({
					id: item.id,
					status: 1
				}).then(res => {
					this.getNoticeList(1,false);
				})
			},
			onMsgClose() {
				this.isMessage = true;
			},
			onShowDialog() {
				this.userDialog = !this.userDialog;
			},
			onCloseDialog(type) {
				this.userDialog = false;
				this.messageDialog = false;
				this.type ? this.showSetPass = false : null;
			},
			onNenewal() {
				this.onCloseDialog()
				getMemberPrice().then(res => {
					this.$router.push({
						path: '/member-renewal',
						query: {
							money: res.msg,
							title: '会员续费'
						}
					});
				})
			},
			/* 修改头像 */
			uploadSuccess(e) {
				console.log("修改头像", e.file);
				this.userInfo.avatar = e.url;
				/** 上传图片 */
				let formData = new FormData();
				formData.append("avatarfile", e.file);
				console.log(formData.get('avatarfile'));
				return
				uploadAvatar(formData).then(response => {
					this.$message.success("修改成功");
				});
			},
			// 编辑头像
			editCropper() {
				this.userDialog = false;
				this.open = true;
				this.$forceUpdate()
			},
			// 打开弹出层结束时的回调
			modalOpened() {
				this.visible = true;
				if (!this.resizeHandler) {
					this.resizeHandler = debounce(() => {
						this.refresh()
					}, 100)
				}
				window.addEventListener("resize", this.resizeHandler)
			},
			// 刷新组件
			refresh() {
				this.$refs.cropper.refresh();
			},
			// 覆盖默认的上传行为
			requestUpload() {},
			// 向左旋转
			rotateLeft() {
				this.$refs.cropper.rotateLeft();
			},
			// 向右旋转
			rotateRight() {
				this.$refs.cropper.rotateRight();
			},
			// 图片缩放
			changeScale(num) {
				num = num || 1;
				this.$refs.cropper.changeScale(num);
			},
			// 上传预处理
			beforeUpload(file) {
				if (file.type.indexOf("image/") == -1) {
					this.$message.error('文件格式错误，请上传图片类型,如：JPG，PNG后缀的文件。');
				} else {
					const reader = new FileReader();
					reader.readAsDataURL(file);
					reader.onload = () => {
						this.options.img = reader.result;
					};
				}
			},
			// 上传图片
			uploadImg() {
				this.$refs.cropper.getCropBlob(data => {
					let formData = new FormData();
					console.log("修改头像data---", data)
					formData.append("avatarfile", data);
					uploadAvatar(formData).then(response => {
						this.open = false;
						this.options.img = process.env.VUE_APP_BASE_API + response.imgUrl;
						this.userInfo.avatar = response.imgUrl;
						this.$message.success("修改成功");
						this.visible = false;
					});
				});
			},
			// 实时预览
			realTime(data) {
				this.previews = data;
			},
			// 关闭窗口
			closeDialog() {
				this.options.img = this.$store.getters.avatar
				this.visible = false;
				window.removeEventListener("resize", this.resizeHandler)
			}
		}
	}
</script>

<style lang="scss" scoped>
	.navbar {
		width: 100%;
		min-height: 180px;
		max-height: 180px;
		background: #FAFAFA;
		display: flex;
		flex-direction: column;

		.navbar-container {
			height: 80px;
			position: relative;

			.home-container {
				line-height: 46px;
				height: 100%;
				float: left;
				cursor: pointer;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;

				.home-wrapper {
					background: linear-gradient(to bottom, #0AA4F1, #7883FE);
					width: 56px;
					height: 56px;
					border-radius: 50%;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					margin-left: 50px;

					.home-icon {
						width: 40px;
						height: 40px;
					}
				}
			}

			.breadcrumb-container {
				float: left;
			}

			.right-menu {
				float: right;
				display: flex;
				flex-direction: row;
				align-items: center;
				height: 100%;

				.user-avatar {
					cursor: pointer;
					width: 50px;
					height: 50px;
					border-radius: 50%;
					margin-right: 50px;
				}

				.user-avatar2 {
					cursor: pointer;
					width: 50px;
					height: 50px;
					border-radius: 50%;
					margin-right: 30px;
				}

			}
		}

		.user-container {
			display: flex;
			flex-direction: row;
			align-items: center;
			margin-left: 50px;
			margin-right: 30px;

			.username {
				flex: 1;
				font-size: 50px;
			}

			.time-container {
				display: flex;
				flex-direction: column;

				.time-title {
					font-weight: 650;
					font-style: normal;
					font-size: 45px;
				}

				.data-title {
					font-weight: 650;
					font-style: normal;
					font-size: 17px;
				}
			}
		}
	}

	.message-dialog {
		position: fixed;
		top: 20px;
		width: 100%;
		height: 100%;
		z-index: 10000;
		display: none;
		flex-direction: row;
		justify-content: flex-end;
	}

	.message-container {
		margin-right: 120px;
		width: 400px;
		max-height: 600px;
		display: flex;
		flex-direction: column;
		border-radius: 10px;
		overflow: hidden;
	}

	.message-title {
		width: 100%;
		height: 80px;
		line-height: 80px;
		font-weight: 650;
		font-style: normal;
		color: #FFFFFF;
		background: linear-gradient(180deg, #4074C2, #4074C2);
		font-size: 32px;
		position: relative;
	}

	.close-icon {
		position: absolute;
		width: 30px;
		height: 30px;
		left: 20px;
		cursor: pointer;
	}

	.item-scrollbar {
		display: flex;
		flex-direction: column;
		width: 100%;
		background: #fff;
		flex: 1;
	}

	.el-scrollbar__wrap.default-scrollbar__wrap {
		overflow-x: auto;
	}


	.item-view {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin: 20px;
		padding-bottom: 10px;
		border-bottom: 1px solid #ccc;
		cursor: pointer;
	}

	.item-icon-view {
		width: 68px;
		height: 68px;
		background: linear-gradient(#6266bf, #112375);
		border-radius: 10px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}

	.msg-icon {
		width: 43px;
		height: 43px;
	}

	.item-data {
		flex: 1;
		display: flex;
		flex-direction: column;
		margin-left: 15px;
	}

	.item-msg-title {
		font-weight: 500;
		font-style: normal;
		font-size: 18px;
		color: #333;
	}

	.item-msg-text {
		font-weight: 400;
		font-style: normal;
		color: rgba(51, 51, 51, 0.5);
		font-size: 13px;
	}

	.del-view {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
	}

	.del-icon {
		width: 26px;
		height: 26px;
	}

	.del-title {
		font-weight: 400;
		font-style: normal;
		font-size: 11px;
		color: rgba(51, 51, 51, 0.5);
		margin-top: 20px;
	}

	.message-content {
		font-weight: 400;
		font-style: normal;
		font-size: 16px;
		color: #262626;
		padding: 15px;
	}

	.dialog-container {
		display: flex;
		position: fixed;
		top: 0px;
		right: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.3);
		z-index: 10000;
		flex-direction: row;
		justify-content: flex-end;
	}

	.dialog-view {
		display: flex;
		flex-direction: column;
		background: #f2f2f2;
		width: 470px;
		max-height: 750px;
		z-index: 20000;
	}

	.dialog-item {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-left: 20px;
		margin-top: 20px;
	}

	.dialog-item-icon {
		width: 70px;
		height: 70px;
		// border-radius: 50%;
		// background: linear-gradient(180deg, #4374b9, #2d4f97);
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.item-icon {
		width: 80px;
		height: 80px;
	}

	.item-icon-head {
		width: 50px;
		height: 50px;
	}

	.item-icon2 {
		width: 32px;
		height: 32px;
		margin-left: 4px;
		margin-right: 4px;
	}

	.item-icon3 {
		width: 28px;
		height: 28px;
		margin-left: 6px;
		margin-right: 6px;
	}


	.item-title-view {
		flex: 1;
		display: flex;
		flex-direction: column;
		margin-left: 30px;
	}

	.item-title {
		font-weight: 650;
		font-style: normal;
		font-size: 22px;
	}

	.item-text2 {
		font-weight: 400;
		font-size: 18px;
		color: #939393;
	}

	.item-text3 {
		font-weight: 100;
		font-style: normal;
		font-size: 18px;
		color: #D02424;
	}

	.item-text {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 140px;
		height: 40px;
		background-color: rgb(22, 155, 213);
		color: #fff;
		font-size: 13px;
		border-radius: 5px;
		margin-right: 30px;
	}

	.dialog-line {
		height: 0.5px;
		background: rgba(121, 121, 121, 0.5);
		margin-left: 30px;
		margin-right: 30px;
		margin-top: 20px;
	}

	.dialog-container2 {
		position: fixed;
		top: 0px;
		box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.5);
	}
	
	.message-dialog::-webkit-scrollbar {
	  display: none;
	  width: 0 !important;
	}
</style>